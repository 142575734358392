import { createWebHistory, createRouter} from "vue-router";
import store from '@/store'
import axios from 'axios'
const routes = [
    {
        name: 'Index',
        component: () => import('./pages/Index.vue'),
        path: '/',
        meta: {
            title: 'Index',
            requiresAuth: false,
            hideNavigation: false,
        },
    },
    {
        name: 'Error-404',
        component: () => import('./pages/404.vue'),
        path: '/Error-404',
        meta: {
            title: '404 Error',
            requiresAuth: false,
            hideNavigation: false,
        },
    },
    {
        name: 'Projects',
        component: () => import('./pages/Projects.vue'),
        path: '/projects',
        meta: {
            title: 'Projects',
            requiresAuth: false,
            hideNavigation: false,
        },
    },
    {
        name: 'Products',
        component: () => import('./pages/Products.vue'),
        path: '/products',
        meta: {
            title: 'Products',
            requiresAuth: false,
            hideNavigation: false,
        },
    },
    {
        name: 'ProductCategory',
        component: () => import('./pages/ProductsCat.vue'),
        path: '/product-cat/:slug',
        meta: {
            title: 'Product Category',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'ProductApplication',
        component: () => import('./pages/ProductApplication.vue'),
        path: '/application-type/:slug',
        meta: {
            title: 'Product Application',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    // {
    //     name: 'ProductsOne',
    //     component: ProductsOne,
    //     path: '/productsOne',
    //     meta: {
    //         title: 'ProductsOne',
    //         requiresAuth: false
    //     },
    // },
    {
        name: 'About',
        component: () => import('./pages/About.vue'),
        path: '/about',
        meta: {
            title: 'About',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'News',
        component: () => import('./pages/News.vue'),
        path: '/our-news',
        meta: {
            title: 'News',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Contact',
        component: () => import('./pages/Contact.vue'),
        path: '/contact',
        meta: {
            title: 'Contact',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Download',
        component: () => import('./pages/Download.vue'),
        path: '/downloads',
        meta: {
            title: 'Download',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Login',
        component: () => import('./pages/Login.vue'),
        path: '/login',
        meta: {
            title: 'Login',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Casambi',
        component: () => import('./pages/Casambi.vue'),
        path: '/casambi',
        meta: {
            title: 'Casambi',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'HumanCentricLighting',
        component: () => import('./pages/HumanCentricLighting.vue'),
        path: '/human-centric-lighting',
        meta: {
            title: 'Human Centric Lighting',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'AntimicrobialProtection',
        component: () => import('./pages/AntimicrobialProtection.vue'),
        path: '/anti-microbial-protection',
        meta: {
            title: 'Antimicrobial Protection',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'CustomLighting',
        component: () => import('./pages/CustomLighting.vue'),
        path: '/custom-lighting',
        meta: {
            title: 'Custom Lighting',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Sustainability',
        component: () => import('./pages/Sustainability.vue'),
        path: '/sustainability',
        meta: {
            title: 'Sustainability',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'TermsConditions',
        component: () => import('./pages/TermsConditions.vue'),
        path: '/terms-conditions',
        meta: {
            title: 'Terms & Conditions',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'CookiePolicy',
        component: () => import('./pages/CookiePolicy.vue'),
        path: '/cookie-policy',
        meta: {
            title: 'Cookie Policy',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'PrivacyPolicy',
        component: () => import('./pages/PrivacyPolicy.vue'),
        path: '/privacy-policy',
        meta: {
            title: 'Privacy Policy',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'EmailNotice',
        component: () => import('./pages/EmailNotice.vue'),
        path: '/e-mail-notice',
        meta: {
            title: 'Email Notice',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'SlaveryAndHumanTraffickingStatement',
        component: () => import('./pages/SlaveryAndHumanTraffickingStatement.vue'),
        path: '/slavery-and-human-trafficking-statement',
        meta: {
            title: 'Slavery And Human Trafficking Statement',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'SiteMap',
        component: () => import('./pages/SiteMap.vue'),
        path: '/site-map',
        meta: {
            title: 'Site Map',
            requiresAuth: false,
            hideNavigation: false
        },
    },
 
 
 
    {
        name: 'ProjectsDetails',
        component: () => import('./pages/ProjectsDetails.vue'),
        path: '/projects/:slug',
        meta: {
            title: 'Projects Details',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Register',
        component: () => import('./pages/Register.vue'),
        path: '/register',
        meta: {
            title: 'Register',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'ThankYou',
        component: () => import('./pages/ThankYou.vue'),
        path: '/thankYou',
        meta: {
            title: 'Thank You',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'FireSafety',
        component: () => import('./pages/FireSafety.vue'),
        path: '/fireSafety',
        meta: {
            title: 'Fire Safety',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'MadeInBritain',
        component: () => import('./pages/MadeInBritain.vue'),
        path: '/made-in-britain',
        meta: {
            title: 'Made In Britain',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'StockAndAvailability',
        component: () => import('./pages/StockAndAvailability.vue'),
        path: '/stockAndAvailability',
        meta: {
            title: 'Stock And Availability',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Service',
        component: () => import('./pages/service.vue'),
        path: '/service',
        meta: {
            title: 'Service',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'DashboardMyAccount',
        component: () => import('./pages/DashboardMyAccount.vue'),
        path: '/dashboard',
        meta: {
            title: 'Dashboard My Account',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'NewsDetails',
        component: () => import('./pages/NewsDetails.vue'),
        path: '/news/:slug',
        meta: {
            title: 'News Details',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'ProductDetails',
        component: () => import('./pages/ProductDetails.vue'),
        path: '/product/:slug',
        meta: {
            title: 'Product Details',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'FamilyProductDetails',
        component: () => import('@/components/ProductDetailsMain/FamilyProducttemplate.vue'),
        path: '/family-product/:slug',
        meta: {
            title: 'Family Product Details',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    // {
    //     name: 'ProjectDetailsTwo',
    //     component: ProjectDetailsTwo,
    //     path: '/projectDetailsTwo/:slug',
    //     meta: {
    //         title: 'ProjectDetailsTwo',
    //         requiresAuth: false
    //     },
    // },
    {
        name: 'ProjectsDetailsOne',
        component: () => import('./pages/ProjectsDetailsOne.vue'),
        path: '/project/:slug',
        meta: {
            title: 'ProjectsDetailsOne',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Forgot',
        component: () => import('./pages/Forgot.vue'),
        path: '/forgot',
        meta: {
            title: 'Forgot Password',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'Wellcome',
        component: () => import('./pages/Wellcome.vue'),
        path: '/wellcome',
        meta: {
            title: 'Wellcome',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'ResetPassword',
        component: () => import('./pages/ResetPassword.vue'),
        path: '/resetpassword/:key/:login',
        meta: {
            title: 'Reset Password',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'AddNewProjectbyproduct',
        component: () => import('./pages/CreateProject.vue'),
        path: '/create-project/:productid',
        meta: {
            title: 'addNewProject',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'viewproject',
        component: () => import('./pages/AddNewProject.vue'),
        path: '/viewproject/:slug',
        meta: {
            title: 'View Project Details',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'LedCustomisation',
        component: () => import('./pages/LedCustomization.vue'),
        path: '/customisation',
        meta: {
            title: 'Led Customisation',
            requiresAuth: false,
            hideNavigation: false
        },
    },
    {
        name: 'LedCustomisationWattage',
        component: () => import('./pages/LedCustomizationWattage.vue'),
        path: '/customisationWattage/:slug',
        meta: {
            title: 'Led Customisation Wattage',
            requiresAuth: false, 
            hideNavigation: false        
        },
    },
    {
        name: 'Customisation',
        component: () => import('./pages/GalaxiaTriangulum.vue'),
          path: '/customisation/:slug',
        meta: {
            title: 'Svg customisation',
            requiresAuth: false,
            hideNavigation: true,
        },
    },
    {
        name: 'ProjectCategory',
        component: () => import('./pages/ProjectCategory.vue'),
          path: '/project-cat/:slug',
        meta: {
            title: 'Project Category',
            requiresAuth: false,
            hideNavigation: false,
        },
    },
    // {
    //     name: 'RicomanUkManufacturingExports',
    //     component: () => import('./pages/RicomanUkManufacturingExports.vue'),
    //       path: '/welcome-to-ricomans-uk-lighting-manufacturing-for-uae-exports',
    //     meta: {
    //         title: 'Ricoman Uk Manufacturing Exports',
    //         requiresAuth: false,
    //         hideNavigation: false,
    //     },
    // },

    {
        name: 'PostRedirection',
        component: () => import('./pages/redirection.vue'),
          path: '/:posttype/:slug',
        meta: {
            title: 'Project Category',
            requiresAuth: false,
            hideNavigation: false,
        },
    },

    {
        name: 'Manufacturing For Uae Exports',
        component: () => import('./pages/ManufacturingForUaeExports'),
          path: '/welcome-to-ricomans-uk-lighting-manufacturing-for-uae-exports',
        meta: {
            title: 'Manufacturing For Uae Exports',
            requiresAuth: false,
            hideNavigation: false,
        },
    },

    {
        name: 'I Joist Ceilings Fire Safety',
        component: () => import('./pages/IJoistCeilingsFireSafety'),
          path: '/i-joist-ceilings-fire-safety',
        meta: {
            title: 'I Joist Ceilings Fire Safety',
            requiresAuth: false,
            hideNavigation: false,
        },
    },

    {
        name: 'Manufacturer For Commercial And Industrial Spaces',
        component: () => import('./pages/ManufacturerForCommercialAndIndustrialSpaces'),
          path: '/ricoman-uk-lighting-manufacturer-for-commercial-and-industrial-spaces',
        meta: {
            title: 'Manufacturer For Commercial And Industrial Spaces',
            requiresAuth: false,
            hideNavigation: false,
        },
    },
       
]

const router = createRouter({
    history: createWebHistory(),
	routes,
    scrollBehavior(to) {
        if(to.hash=='#BrochuresBox' || to.hash=='#MeetTheFamily'){
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
        else{
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        }        
    },
});


router.beforeEach((to, from, next) => { 
    const nextRoute = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    axios.get('https://dev.ricoman.com/redirectrules.json').then((res)=>{
        const jsondatas =res.data;           
        if(jsondatas.redirectionrul){           
           
            let findresult=jsondatas.redirectionrul.find(resdata => resdata.oldeurl== to.path);
            if(to.path=='/%22/human-centric-lighting//%22')
               next({ path: '/human-centric-lighting/'});
            if(to.path=='/js/' || to.path=='/index.php')
               next({ path: '/'});
            if(findresult){
                if(findresult.redirecturl=='https://dev.ricoman.com/' || findresult.redirecturl=='https://www.dev.ricoman.com/')  
                 next({ path: '/'});
                else   
                 next({ path: findresult.redirecturl});
            }
            else{
                if (nextRoute) {
                    document.title = nextRoute.meta.title;
                    const isAuthorised = store.state.authModule.isUserLoggedIn;            
                    if (isAuthorised && ['Forgot', 'Login', 'ResetPassword','Register'].includes(nextRoute.name)) {
                        next({ name: 'DashboardMyAccount' });
                    } 
                    else if (!isAuthorised && ['DashboardMyAccount'].includes(nextRoute.name)) {
                        next({ name: 'Login' });
                    } 
                    else if (!isAuthorised && nextRoute && nextRoute.meta.requiresAuth) {
                        next({ name: 'Error-404' });
                    }
                
                    if (!to.matched.length) {
                        next({ name: 'Error-404' });
                    } 
                    else {
                        var params={posttype:to.params.posttype,slug:to.params.slug,path:to.path}
                        let apiurl=process.env.VUE_APP_API_BASE_URL
                        if(to.params.slug){
                            axios.get(`${apiurl}wp/v2/checkcustomparmalink`, { params }).then((resp) => { 
                                let response=resp.data;
                                if(response.slug==to.params.slug || response.status=='error'){
                                    //console.log(response)
                                    next(); 
                                }else{
                                    if(response.type=='product'){
                                        next({ path: 'product/'+response.slug})
                                    }
                                    else if(response.type=='news'){
                                        next({ path: 'news/'+response.slug})
                                    }

                                    else if(response.type=='project'){
                                        next({ path: 'projects/'+response.slug})
                                    }
                                    else{
                                        next();
                                    }
                                }
                                }).catch((err) => {       
                                    next();
                                });
                          }
                        else{
                            next();
                        }
                    }
                }else{
                    var params={posttype:to.params.posttype,slug:to.params.slug,path:to.path}
                    let apiurl=process.env.VUE_APP_API_BASE_URL 
                    axios.get(`${apiurl}wp/v2/checkcustomparmalink`, { params }).then((resp) => { 
                        let response=resp.data;
                        if(response.status=='error') {
                            next({ name: 'Error-404'})
                        }
                            
                        else {
                            if(response.type=='product'){
                                next({ path: 'product/'+response.slug})
                            }
                            else if(response.type=='news'){
                                next({ path: 'news/'+response.slug})
                            }
                            else if(response.type=='project'){
                                next({ path: 'projects/'+response.slug})
                            }
                            else{
                                next({ name: 'Error-404'});  
                            }
                        }
                       }).catch((err) => {  
                           console.log(err);         
                            next({ name: 'Error-404' });
                      });
                }
            
               
            }
        } 
    }).catch((err)=>{
        next({ path: '/'});
    })    
});

export default router;


